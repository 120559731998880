import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { flushSync } from "react-dom";
import Navigation from "../Navigation";
import { useLocation } from 'react-router-dom';
import { DayAgenda } from "../../../types";
import gsap_anims from "../../helpers/gsap_anims";
import { Button } from "bootstrap";

interface AgendaProps {
  agenda: { agenda: DayAgenda[] } | undefined;
  events:
    | { currentEvents: any; upcomingEvents?: any; todayAgenda?: any }
    | undefined;
  screensaver: Boolean;
  setMap: Function;
  map: Boolean
}

const Agenda: React.FC<AgendaProps> = ({ agenda, events, screensaver, setMap, map }) => {
  const location = useLocation();

  const [days, setDays] = useState<DayAgenda[]>([]);
  const [activeTab, setActiveTab] = useState<any>();
  const [anim, setAnim] = useState<Boolean>(false);
  const hasRunRef = useRef(false);
  const containerRef = useRef<any | undefined>(null);
  const [isDragging, setIsDragging] = useState(false);

  const firstUpcomingEvent = events?.upcomingEvents?.events[0];

  useEffect(() => {
    if (agenda?.agenda) {
      setDays((prevDays) => {
        const existingDates = new Set(prevDays.map((day) => day.date));
        const newDays = agenda.agenda.filter(
          (day) => !existingDates.has(day.date)
        );
        return [...prevDays, ...newDays];
      });
    }
  }, [agenda]);

  useEffect(() => {
    if (!hasRunRef.current && events?.todayAgenda) {
      days.map((day, index) => {
        if (day.date === events?.todayAgenda?.todayAgenda.date) {
          setActiveTab(index);
        }
      });
      hasRunRef.current = true;
    } else if(!hasRunRef.current) {
      setActiveTab(0)
      hasRunRef.current = true;
    }
  }, [days, events?.todayAgenda, location.pathname]);

  
  const scrollToElement = () => {
    // MOVE TO ELEMENT
    let activeElement = document.querySelector(".active-event");
    let previousSibling = activeElement?.previousElementSibling;
    let prev_previousSibling = previousSibling?.previousElementSibling;
      console.log(activeElement)
      prev_previousSibling?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

  };

  const callbackItems = () => {
    gsap_anims.title();
    gsap_anims.show("#agenda .gsap-stag-show");
  };

  useEffect(() => {

    if (activeTab >= 0 && !anim && agenda ) {
      // console.log("enter")
      setTimeout(() => {
        gsap_anims.show_moving_up(
          "#agenda .gsap-stag-moving-up",
          callbackItems()
        );

      }, 500);

      setAnim(true);
      setTimeout(() => {
        scrollToElement();
      }, 1000);
    }
  }, [activeTab, anim, location.pathname, agenda]);

  useEffect(() => {
    hasRunRef.current = false;
  }, [location.pathname, screensaver]);

  const parseDate = (isoString: any) => {
    return new Date(isoString);
  };

  const formatDate = (date: any) => {
    const dayOptions = { day: "numeric" };
    const weekdayOptions = { weekday: "long" };

    const dayFormatter = new Intl.DateTimeFormat("en-EN", dayOptions as Object);
    const weekdayFormatter = new Intl.DateTimeFormat(
      "en-EN",
      weekdayOptions as Object
    );

    const dayNumber = dayFormatter.format(date);
    const dayOfWeek = weekdayFormatter.format(date);

    const suffix =
      dayNumber.endsWith("1") && dayNumber !== "11"
        ? "st"
        : dayNumber.endsWith("2") && dayNumber !== "12"
        ? "nd"
        : dayNumber.endsWith("3") && dayNumber !== "13"
        ? "rd"
        : "th";

    return `${dayOfWeek}, ${dayNumber}${suffix}`;
  };

  const isBeforeDate = (eventDateString: string) => {
    const today = new Date();
    const eventDate = new Date(eventDateString);

    const todayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const eventDateOnly = new Date(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate()
    );

    return eventDateOnly < todayDate;
  };

  const isEventOver = (eventEnds: string) => {
    const currentTime = new Date();
    const eventEndTime = new Date(`${currentTime.toDateString()} ${eventEnds}`);

    return eventEndTime <= currentTime;
  };


  return (
    <>
      <Navigation setMap={setMap} map={map}/>
      <div
        id="agenda"
        className="h-[84%] w-full text-center flex justify-start flex-col flex-nowrap items-center z-10"
      >
        {/* Tabs */}
        <div id="draggable-tab" className="flex justify-start min-h-[160px] w-full overflow-x-auto overflow-hidden px-14 pt-4">
          {days.map((day, index) => (
            <button
              key={index}
              className={`button-day h-24 min-w-fit px-10 rounded-full mr-4 border-2 text-2xl gsap-stag-moving-up ${
                activeTab === index
                  ? "bg-button-active text-white text-medium border-active border-solid"
                  : "text-white text-medium border-agenda-btn-gradient"
              }`}
              onClick={(e: any) => { 

                if(!e.target.classList.value.includes('bg-button-active') && !isDragging){ // Not reload if btn is active
                  setActiveTab(index); 
                  gsap_anims.stagger(".gsap-stag-show");
                  e.target.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                  setTimeout(() => {
                    containerRef.current.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }, 500);
                }
            }}
            >
              <>
                {formatDate(parseDate(day.date))}
              </>
            </button>
          ))}
        </div>

        <div className="w-full overflow-y-auto h-full">
          {/* Content for each tab */}
          <div className="h-full overflow-auto flex justify-start flex-row ">
            {days[activeTab] && (
              <>
                <ul
                  className="h-full w-full pb-[50%] overflow-auto overflow-x-hidden px-14 py-10"
                  ref={containerRef}
                >
                  {/* When having agenda */}
                  { events?.todayAgenda && days[activeTab].events.map((event, index) => (
                    <li
                      key={index}
                      className={`mb-2 w-full gsap-stag-show relative ${
                        events?.todayAgenda && events?.todayAgenda.length > 0 && days[activeTab].date &&
                        event?.starts ===
                          (events?.currentEvents[index]?.starts) &&
                        event.ends ===
                          (events?.currentEvents[index]?.ends ) &&
                        "active"
                      }`}
                    >

                      {/* OLD IMAGE FOR REFERENCE */}
                      {/* <img
                        src={`/assets/agenda/day${activeTab + 1}/${
                          index + 1
                        }.png`}
                        className={`w-full h-full p-10 ${
                          (isBeforeDate(days[activeTab].date) ||
                            (days[activeTab].date.split("T")[0] ==
                              new Date().toISOString().split("T")[0] &&
                              isEventOver(event.ends))) &&
                          "opacity-30"
                        }`}
                      /> */}

                      {/* HTML INFO */}
                      <div className={`row flex justify-start items-center w-full relative px-10 py-5 h-36 ${
                          (isBeforeDate(days[activeTab].date) ||
                            (days[activeTab].date.split("T")[0] ==
                              new Date().toISOString().split("T")[0] &&
                              isEventOver(event.ends))) &&
                          "opacity-30"
                        }`}>
                        <div className="w-[10%] h-full justify-center items-center align-top text-left flex-col">
                          <p className="text-[#fd7801] text-4xl font-bold">{event.starts}</p>
                          <p className="text-[#ffffff]/50 text-2xl mt-2">{event.ends}</p>
                        </div>

                        <div className="w-[6px] ml-10 h-full flex-col bg-button-active"></div>

                        <div className="w-[80%] h-full ml-12 info text-left justify-around items-left align-top flex flex-col">
                          <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.2} stroke="#d8d8d8" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>
                            <p className="text-[rgb(216,216,216)] text-2xl ml-2">{event.place}</p>
                          </div>                              
                          <p className="text-white text-4xl mt-2">{event.title}</p>
                        </div>
                      </div>

                      { index != days[activeTab].events.length - 1 && 
                        <div className="w-full h-5 mt-4 flex justify-center">
                          <div className="w-4/5 h-[1px] bg-agenda-divider"></div>
                        </div>
                      }


                      { events?.currentEvents.length && events?.todayAgenda?.todayAgenda.date ===
                        days[activeTab].date &&
                        events?.currentEvents.some(
                          (happeningEvent: any) =>
                            happeningEvent.starts === event.starts &&
                            happeningEvent.ends === event.ends
                        )
                        && (
                          <div className="w-auto h-[40px] absolute right-24 z-30 rounded-full top-[20%] transform translate-y-[-50%] active-event">
                            <img className="flex justify-center h-full items-center" src="/assets/events/inProgress.png" />
                          </div>
                        )}

                      { events?.currentEvents.length === 0 &&
                        events?.upcomingEvents &&
                        events?.upcomingEvents.date == days[activeTab].date &&

                        firstUpcomingEvent &&
                        firstUpcomingEvent.starts === event.starts &&
                        firstUpcomingEvent.ends === event.ends && (
                          <div className="w-auto h-[40px] absolute top-[20%] translate-y-[-50%] right-24 z-30 rounded-full active-event">
                            <img
                              className="flex justify-center h-full items-center"
                              src="/assets/events/comingNext.png"
                            />
                          </div>
                        )}
                    </li>
                  ))}

                  {/* When no agenda ( days before or after event ) */}
                  { !events?.todayAgenda && days[activeTab].events.map((event, index) => ( 
                      <li
                      key={index}
                      className={`mb-2 w-full gsap-stag-show relative`}
                    >

                      {/* HTML INFO */}
                        <div className="row flex justify-start items-center w-full relative px-10 py-5 h-36 ">
                          <div className="w-[10%] h-full justify-center items-center align-top text-left flex-col">
                            <p className="text-[#fd7801] text-4xl font-bold">{event.starts}</p>
                            <p className="text-[#ffffff]/50 text-2xl mt-2">{event.ends}</p>
                          </div>

                          <div className="w-[6px] ml-10 h-full flex-col bg-button-active"></div>

                          <div className="w-[80%] h-full ml-12 info text-left justify-around items-left align-top flex flex-col">
                            <div className="flex items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.2} stroke="#d8d8d8" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                              </svg>
                              <p className="text-[rgb(216,216,216)] text-2xl ml-2">{event.place}</p>
                            </div>                              
                            <p className="text-white text-4xl mt-2">{event.title}</p>
                          </div>
                        </div>

                        { 
                        activeTab === 0 &&
                        event && index == 0 && (
                          <div className="w-auto h-[40px] absolute top-[20%] translate-y-[-50%] right-24 z-30 rounded-full active-event">
                            <img
                              className="flex justify-center h-full items-center"
                              src="/assets/events/comingNext.png"
                            />
                          </div>
                        )}

                        { index != days[activeTab].events.length - 1 && 
                          <div className="w-full h-5 mt-4 flex justify-center">
                            <div className="w-4/5 h-[1px] bg-agenda-divider"></div>
                          </div>
                        }

                    </li>
                  ))}

                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Agenda;
