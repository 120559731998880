import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { DayAgenda } from "../../../types";

import Navigation from "../Navigation";

import gsap_anims from "../../helpers/gsap_anims";

interface ActivitiesProps {
  activities: { agenda: DayAgenda[] } | undefined;
  time: Date;
  eventsActivities:
    | { currentEvents: any; upcomingEvents?: any; todayAgenda?: any }
    | undefined;
  screensaver: Boolean;
  setMap: Function;
  map: Boolean;
}

const Activities: React.FC<ActivitiesProps> = ({
  activities,
  time,
  eventsActivities,
  screensaver,
  setMap,
  map,
}) => {
  const navigate = useNavigate();

  const location = useLocation();

  const [days, setDays] = useState<DayAgenda[]>([]);
  const [activeTab, setActiveTab] = useState<any>();
  const [anim, setAnim] = useState<Boolean>(false);
  const hasRunRef = useRef(false);
  const containerRef = useRef<any | undefined>(null);
  const [isDragging, setIsDragging] = useState(false);

  const firstUpcomingEvent = eventsActivities?.upcomingEvents?.events[0];

  useEffect(() => {
    if (activities?.agenda) {
      setDays((prevDays) => {
        const existingDates = new Set(prevDays.map((day) => day.date));
        const newDays = activities.agenda.filter(
          (day) => !existingDates.has(day.date)
        );
        return [...prevDays, ...newDays];
      });
    }
  }, [activities]);

  useEffect(() => {
    if (!hasRunRef.current && eventsActivities?.todayAgenda) {
      days.map((day, index) => {
        if (day.date === eventsActivities?.todayAgenda?.todayAgenda.date) {
          setActiveTab(index);
        }
      });

      hasRunRef.current = true;
    } else if (!hasRunRef.current) {
      setActiveTab(0);
      hasRunRef.current = true;
    }
  }, [days, eventsActivities?.todayAgenda, location.pathname]);

  const scrollToElement = () => {
    // MOVE TO ELEMENT
    let activeElement = document.querySelector(".active");
    let previousSibling = activeElement?.previousElementSibling;
    previousSibling?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const callbackItems = () => {
    gsap_anims.title();
    gsap_anims.show("#activities .gsap-stag-moving-up");
    gsap_anims.show("#activities .gsap-stag-show-activities");
  };

  useEffect(() => {
    if (activeTab >= 0 && !anim) {
      // console.log("enter")
        gsap_anims.show_moving_up(
          "#activities .gsap-stag-show",
          callbackItems()
        );

      setAnim(true);
      setTimeout(() => {
        scrollToElement();
      }, 1500);
    }
  }, [activeTab, anim, location.pathname]);

  useEffect(() => {
    hasRunRef.current = false;
  }, [location.pathname, screensaver]);

  const parseDate = (isoString: any) => {
    return new Date(isoString);
  };

  const formatDate = (date: any) => {
    const dayOptions = { day: "numeric" };
    const weekdayOptions = { weekday: "long" };

    const dayFormatter = new Intl.DateTimeFormat("en-EN", dayOptions as Object);
    const weekdayFormatter = new Intl.DateTimeFormat(
      "en-EN",
      weekdayOptions as Object
    );

    const dayNumber = dayFormatter.format(date);
    const dayOfWeek = weekdayFormatter.format(date);

    const suffix =
      dayNumber.endsWith("1") && dayNumber !== "11"
        ? "st"
        : dayNumber.endsWith("2") && dayNumber !== "12"
        ? "nd"
        : dayNumber.endsWith("3") && dayNumber !== "13"
        ? "rd"
        : "th";

    return `${dayOfWeek}, ${dayNumber}${suffix}`;
  };

  const isBeforeDate = (eventDateString: string) => {
    const today = new Date();
    const eventDate = new Date(eventDateString);

    const todayDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const eventDateOnly = new Date(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate()
    );

    return eventDateOnly < todayDate;
  };

  const isEventOver = (eventEnds: string) => {
    const currentTime = new Date();
    const eventEndTime = new Date(`${currentTime.toDateString()} ${eventEnds}`);

    return eventEndTime <= currentTime;
  };

  useEffect(() => {
    if (screensaver) {
      navigate("/");
    }
  }, [screensaver]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

  }, [activeTab])
  

  return (
    <>
      <Navigation setMap={setMap} map={map} />
      <div
        id="activities"
        className="h-[84%] w-full text-center flex justify-start flex-col flex-nowrap items-center z-10"
      >
        <div className="flex justify-start w-full px-14 pt-4 text-white text-high text-left gsap-stag-show">
          Fuel your inspiration. Connect with colleagues.
          <br />
          Explore the Eternal City.
        </div>

        {/* Tabs */}
        <div
          id="draggable-tab"
          className="flex justify-start min-h-[150px] w-full overflow-x-auto overflow-hidden px-14 pt-8 mt-8"
        >
          {days.map((day, index) => (
            <button
              key={index}
              className={`button-day h-24 min-w-fit px-10 rounded-full mr-4 border-2 text-2xl gsap-stag-moving-up ${
                activeTab === index
                  ? "bg-button-active text-white text-medium border-active border-solid"
                  : "text-white text-medium border-agenda-btn-gradient"
              }`}
              onClick={(e: any) => {

                if (!e.target.classList.value.includes("bg-button-active")) {
                  // Not reload if btn is active
                  setActiveTab(index);
                  gsap_anims.stagger(".gsap-stag-show-activities");
                  e.target.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  });
                }
              }}
            >
              <>
                {formatDate(parseDate(day.date))}
              </>
            </button>
          ))}
        </div>

        <div className="w-full overflow-y-auto h-full">
          {/* Content for each tab */}
          <div className="h-full overflow-auto flex justify-start flex-row py-10">
            {days[activeTab] && (
              <>
                <ul
                  className="h-full w-full pb-[50%] overflow-auto overflow-x-hidden px-14"
                  ref={containerRef}
                >
                  {/* When having agenda */}
                  {eventsActivities?.todayAgenda &&
                    days[activeTab].events.map((event, index) => (
                      <div
                        key={index}
                        className={`row flex flex-col justify-start w-full relative p-10 rounded-4xl h-auto mb-10 events-gradient opacity-0 gsap-stag-show-activities`}
                      >
                        {/* TITLE */}
                        <div className="text-[#fd7801] font-semibold text-high text-left">
                          {event.title}
                        </div>

                        {/* DESCRIPTION */}
                        <div className="mt-10">
                          <p className="text-white text-medium text-left whitespace-pre-line">
                            {event.description}
                          </p>
                        </div>

                        {/* FOOTER */}
                        <div className="text-white mt-24 inline-flex items-center">
                          {/* PLACE */}
                          <div className="w-2/4 justify-start inline-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.2}
                              stroke="#d8d8d8"
                              className="size-10"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                              />
                            </svg>
                            <p className="text-white text-medium text-left ml-5">
                              {event.place}
                            </p>
                          </div>

                          {/* STARTS */}
                          <div className="w-2/4 justify-end inline-flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="white"
                              className="size-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                            <p className="text-white text-medium ml-5">
                              {event.starts}
                            </p>
                          </div>
                        </div>

                        {event.disclaimer && 
                          <div className="mt-[1px] flex justify-start items-center">
                            <ol className="list-disc text-high flex items-center ml-6 justify-center">
                              <li className="ml-4">
                                <p className="text-white/50 text-small text-left whitespace-pre-line">
                                  {event.disclaimer}
                                </p>
                              </li>
                            </ol>
                          </div>
                        }

                      </div>
                  ))}

                  {/* When no agenda ( days before or after event ) */}
                  {!eventsActivities?.todayAgenda &&
                    days[activeTab].events.map((event, index) => (
                      <div
                        key={index}
                        className="row flex flex-col justify-start w-full relative p-10 rounded-4xl h-auto mb-10 events-gradient opacity-0 gsap-stag-show-activities"
                      >
                        {/* TITLE */}
                        <div className="text-[#fd7801] font-semibold text-high text-left">
                          {event.title}
                        </div>

                        {/* DESCRIPTION */}
                        <div className="mt-10">
                          <p className="text-white text-medium text-left whitespace-pre-line">
                            {event.description}
                          </p>
                        </div>

                        {/* FOOTER */}
                        <div className="text-white mt-24 inline-flex items-center">
                          {/* PLACE */}
                          <div className="w-2/4 justify-start inline-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.2}
                              stroke="#d8d8d8"
                              className="size-10"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                              />
                            </svg>
                            <p className="text-white text-medium text-left ml-5">
                              {event.place}
                            </p>
                          </div>

                          {/* STARTS */}
                          <div className="w-2/4 justify-end inline-flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="white"
                              className="size-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                            <p className="text-white text-medium ml-5">
                              {event.starts}
                            </p>
                          </div>
                        </div>


                        {event.disclaimer && 
                          <div className="mt-[1px] flex justify-start items-center">
                            <ol className="list-disc text-high flex items-center ml-6 justify-center">
                              <li className="ml-4">
                                <p className="text-white/50 text-small text-left whitespace-pre-line">
                                  {event.disclaimer}
                                </p>
                              </li>
                            </ol>
                          </div>
                        }

                      </div>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Activities;
