import $ from "jquery";
import gsap from "gsap";
const gsap_anims = {
    show: (element, callback) => {
        gsap.fromTo(element, { opacity: 0, y: 10 }, { opacity: 1,y:0, delay: 1.7, stagger: 0.10, onComplete: () => {
            if(callback){
                callback()
            }
        }})
    },
    show_moving_up: (element, callback) => {
        gsap.fromTo(element, { opacity: 0, x: 10 }, { opacity: 1, x:0, delay: 1.3, onComplete: () => {
            if(callback){
                callback()
            }
        }})
    },
    stagger: (element, callback) => {
        const elements = document.querySelectorAll(element);
        // Loop through each element and set its opacity to 0
        elements.forEach(element => {
            element.style.opacity = '0';
        });  
      setTimeout(() => {
      gsap.fromTo(element, { opacity: 0, y: 10 }, { opacity: 1, y:0, stagger: 0.10, delay: 0, onComplete: () => {
            if(callback){
                callback()
            }
        }})
    }, 500); // Delay in milliseconds
    },
    intro: (callback) => {
        const tlintro = gsap.timeline();

        // Add animations using fromTo()
        tlintro.fromTo("#header", 
            { y: -100, scale: 1, opacity: 0 },  // From state
            { y: 0, scale: 1, opacity: 1, duration: 1, ease: "power2.out" }  // First tween
        )
         
        gsap.fromTo(".slash", 
        { y: 10, scale: 1, opacity: 0 },  // From state
        { y: 0, scale: 1, opacity: 1, duration: 1, ease: "power2.out" }  // First tween
        )
     
        tlintro.play();    
    
    // Animate the logo container
    
        gsap.fromTo(".logo-container", 
            { x: -100, scale: 1, opacity: 0 },  // From state
            { x: 0, scale: 1, opacity: 1, delay: 0, duration: 0.5, ease: "power2.out" }  // To state
        );
    
    
    // Animate the time element
    
        gsap.fromTo(".time", 
            { x: 100, scale: 1, opacity: 0 },  // From state
            { x: 0, scale: 1, opacity: 1, delay: 0, duration: 0.5, ease: "power2.out" }  // To state
        );
    
    
    // Animate the wifi button
    
        gsap.fromTo(".wifi-btn", 
            {   y: -30,scale: 1, opacity: 0 },  // From state
            {    y: 0, scale: 1, opacity: 1, delay: 0.5, duration: 1, ease: "power2.out" }  // To state
        );
    
    
    // Animate the wifi button
    
    gsap.fromTo(".navigation", 
        {  y: 30,scale: 1, opacity: 0 },  // From state
        {  y: 0,scale: 1, opacity: 1, delay: 1, duration: 1, stagger: 0.25, ease: "power2.out" }  // To state
    );
    
    gsap.fromTo(".nav-btn", 
        {  x: 30,scale: 1, opacity: 0 },  // From state
        {  x: 0,scale: 1, opacity: 1, delay: 1.5, duration: 1, stagger: 0.25, ease: "power2.out" 
            , onComplete: () => {
                if(callback){
                    callback()
                }
            }
        } 
    );
    
},  title: (callback) => {

        setTimeout(function() {
          
     
gsap.fromTo(".navigation", 
    {  y: 30,scale: 1, opacity: 0 },  // From state
    {  y: 0,scale: 1, opacity: 1, delay: 0, duration: 1, stagger: 0.25, ease: "power2.out" }  // To state
);
}, 500); // 1000 milliseconds = 1 second

}
}


export default gsap_anims;