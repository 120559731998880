import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import $ from "jquery";
import "./App.css";

// Global types interface
import { DayAgenda, Event } from "./types";

// Import gsap and custom module for anims
import gsap_anims from "./components/helpers/gsap_anims";
import findCurrentAndNextEvents from "./components/helpers/getEvents";
import { getDeviceOrientation } from "./components/helpers/devicePositionHelper";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import Agenda from "./components/screens/Agenda/Agenda";
import Activities from "./components/screens/Activities/Activities";

const App: React.FC = () => {
  const [agenda, setAgenda] = useState<{ agenda: DayAgenda[] }>();
  const [events, setEvents] = useState<{
    currentEvents: Event[] | null;
    upcomingEvents?: { events: Event[]; date: string } | null;
    todayAgenda?: any | null;
  }>();

  const [activities, setActivities] = useState<{ agenda: DayAgenda[] }>();
  const [eventsActivities, setEventsActivities] = useState<{
    currentEvents: Event[] | null;
    upcomingEvents?: { events: Event[]; date: string } | null;
    todayAgenda?: any | null;
  }>();

  const [time, setTime] = useState(new Date());
  const [showDebugger, setShowDebugger] = useState(false);
  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>(getDeviceOrientation());
  const [isOpen, setIsOpen] = useState(false);
  const [positionMap, setPositionMap] = useState({pos: '', x: 0, y: 0});

  const videosArray: Array<String> = [
    "/assets/videos/Rome.webm",
    "/assets/videos/Racer.webm",
    "/assets/videos/Ol2.webm",
  ];
  const [currentVideo, setcurrentVideo] = useState<String>();
  const [screensaver, setScreenSaver] = useState(false);
  const [modal, setModal] = useState(false);
  const [wifi, setWifi] = useState(false);
  const [map, setMap] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Handler Debugger
  let clickCountdb: number = 0;
  let lastClickTimedb: number = 0;
  const [timerModal, setTimerModal] = useState(10);
  const handlerDebugger = () => {
    const now = new Date().getTime();

    if (now - lastClickTimedb <= 20000 || clickCountdb === 0) {
      clickCountdb++;
      console.log(clickCountdb)

      if (clickCountdb === 3) {
        setShowDebugger(!showDebugger);
        clickCountdb = 0;
      }
    } else {
      clickCountdb = 1;
    }

    lastClickTimedb = now;
  };

  // Heartbeat
  const timeoutId = useRef<any | null>(null);
  const timeoutIdModal = useRef<any | null>(null);
  const intervalIdModal = useRef<any | null>(null);

  function restartTabs()  {

    setTimeout(() => {   

    // MOVE TO ELEMENT
    let activeElement = document.querySelector(".active-event");
    let previousSibling = activeElement?.previousElementSibling;
    let prev_previousSibling = previousSibling?.previousElementSibling;
    console.log("RESTARTABS")
      prev_previousSibling?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    }, 500);

    setTimeout(() => {   
     // SCROLL BUTTONS
 let activeBtn = document.querySelector("#draggable-tab .bg-button-active");
 let firstChild = activeBtn?.firstElementChild;
  activeBtn?.scrollIntoView({
  behavior: "smooth",
  block: "nearest",
  inline: "center",
});
}, 2000);

  };

  const handleTimeout = async () => {
    const currentLocation = window.location;
    try {
       const random = Math.floor(Math.random() * videosArray.length);
       const getRandomVideo = videosArray[random];

      setcurrentVideo(getRandomVideo);

      if (currentLocation.pathname === "/") {
        setMap(false);
        setWifi(false);
        $("#container").delay(200).fadeOut();

        setTimeout(() => {
        gsap.fromTo(".touch-badge",
          { y: 100, scale: 1, opacity: 0 },  // From state
          { y: 0, scale: 1, opacity: 1, delay: 0, duration: 1, ease: "power2.out" }  // To state
        );}, 2000);
        return setScreenSaver(true);
      }

      await setMap(false);
      await setWifi(false);

      setModal(true);

      timeoutIdModal.current = setTimeout(() => {
        setModal(false);
        setScreenSaver(true);
        clearInterval(intervalIdModal.current);
        setTimerModal(10);
      }, 11000);

      intervalIdModal.current = setInterval(() => {
        setTimerModal((prev) => prev - 1);
      }, 1000);
    } catch (error) {
      console.error("Error selecting random video:", error);
    }
  };

  const resetTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId.current);
      setScreenSaver(false);
    }

    if (intervalIdModal) {
      clearInterval(intervalIdModal.current);
      setTimerModal(10);
    }
    timeoutId.current = setTimeout(handleTimeout, 25000); // Timer for heartbeat in ms
  };

  const handleClick = (event: MouseEvent | TouchEvent) => {
    if (
      modal ||
      (event.target instanceof Node &&
        !document.getElementById("inactivity-modal")?.contains(event.target))
    ) {
      resetTimeout();
    }
  };

  const handleStayActive = () => {
    if (timeoutIdModal) {
      clearTimeout(timeoutIdModal.current);
    }
    if (timeoutId) {
      console.log("clearTimeout")

    }

    if (intervalIdModal) {
      clearInterval(intervalIdModal.current);
    }
   
    setModal(false);
    
  };

  useEffect(() => {
    resetTimeout();

    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Attach click listener to the document
    document.addEventListener("click", (event) => handleClick(event));
    document.addEventListener("touchstart", (event) => handleClick(event));


    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId.current);
      }
      if (timeoutIdModal) {
        clearTimeout(timeoutIdModal.current);
      }

      document.removeEventListener("click", handleClick);
    };
  }, []);

  // Fetching agenda
  useEffect(() => {

    const generateHash = () => Math.random().toString(36).substring(7);

    const fetchAgenda = async () => {
      try {
        const hashAgenda = generateHash();
        const response = await fetch(`/agenda.json?_=${hashAgenda}`); // Change between dev and prod
        const data: { agenda: DayAgenda[] } = await response.json();
        setAgenda(data);
      } catch (error) {
        console.error("Error fetching agenda:", error);
      }
    };

    fetchAgenda();
  }, []);

  useEffect(() => {
    async function getEvents() {
      let events = await findCurrentAndNextEvents(agenda, time);
      setEvents(events);
    }

    getEvents();
  }, [agenda, time]);

  // fetching activities
  useEffect(() => {

    const generateHashActivities = () => Math.random().toString(36).substring(7);

    const fetchActivities = async () => {
      try {
        const hashActivities = generateHashActivities();
        const response = await fetch(`/activities.json?_=${hashActivities}`); // Change between dev and prod
        const data: { agenda: DayAgenda[] } = await response.json();
        setActivities(data);
      } catch (error) {
        console.error("Error fetching agenda:", error);
      }
    };

    fetchActivities();
  }, []);

  useEffect(() => {
    async function getEventsActivities() {
      let events = await findCurrentAndNextEvents(activities, time);
      setEventsActivities(events);
    }

    getEventsActivities();
  }, [activities, time]);

  const handleWifi = () => {
    setWifi(!wifi);

    if(!wifi){
      gsap_anims.stagger("#wifi-modal .wifi-modal-stag")
    }
  };

  const HardReload = () => {
    // TODO: clean cache without using new url 
    window.location.reload()
  }

  // Position device
  useEffect(() => {
    const handleResize = () => {
      setOrientation(getDeviceOrientation());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  const settingPosMap = (pos: number) => {
    if(pos === 1){
      setPositionMap({
        pos: '1',
        x: 720,
        y: 295
      })
    } else {
      setPositionMap({
        pos: '2',
        x: 690,
        y: 470
      })
    }
  }

  // IMAGE PINCH ZOOM
  const imgRef = useRef<HTMLImageElement | null>(null);
  const onUpdate = useCallback(({ x, y, scale }: { x: number; y: number; scale: number }) => {
    const { current: img } = imgRef;
  
    if (img) {
      // Aplica la transformación (traslación y escala) solo a la imagen
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, [positionMap.x, positionMap.y]);

  const toggleFullscreen = () => {
    const element = document.documentElement; // Cambia esto si quieres otro elemento
    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  return (
    <BrowserRouter>
      <div className="h-screen w-screen">
        {/* Device orientation */}
        { orientation === "landscape" &&  
          <div 
          id="orientation-modal"
          className="w-full h-full fixed inset-0 flex flex-col items-center justify-center bg-opacity-40 z-[150] bg-timer-bg">
            <div className="absolute top-50 left-50 translate-x-50">
              <p className="text-white text-high uppercase text-center">PLease Rotate<br/>your Device Vertically</p>
            </div>
          </div>
        }

        {/* Debbuger */}
        <div
          id="handler-debugger"
          className="bg-[#1d1939] absolute right-0 h-20 w-20 opacity-0 z-[1000] select-none"
          onClick={() => handlerDebugger()}
        ></div>
        {showDebugger && (
          <div
            id="debugger"
            className="bg-[#1d1939] h-fit  w-2/6 absolute right-0 top-28 rounded-2xl px-6 py-4 z-50 flex justify-start flex-col"
          >
            <div className="relative">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="white"
                  className="size-10 absolute top-0 right-0"
                  onClick={() => setShowDebugger(false)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
            </div>


            <p className="text-white font-normal text-xl uppercase mb-6 mt-2">Local Time: <span className="font-bold">{time.toLocaleTimeString()}</span></p>

            <div className="flex justify-center mb-6">
              <button 
                onClick={toggleDropdown} 
                className="text-white text-[14x] border-solid rounded-3xl bg-clock px-4 py-2 uppercase w-56"
              >
                Set Map position
              </button>

              {/* Options for positions */}
              {isOpen && (
                <div className="absolute mt-14 bg-[#17142d] rounded-md shadow-lg w-56 p-2">
                  <ul className="flex flex-col">
                    <button className={`p-2 text-white text-[14x] border-solid border-2 rounded-xl mb-2 mt-2 ${ positionMap.pos === '1' ? "bg-button-active" : "bg-gray-dark/95" }`} onClick={() => settingPosMap(1)}>Sfera</button>
                    <button className={`p-2 text-white text-[14x] border-solid border-2 rounded-xl mb-2 ${ positionMap.pos === '2' ? "bg-button-active" : "bg-gray-dark/95" }`} onClick={() => settingPosMap(2)}>Cosmo</button>
                  </ul>
                </div>
              )}
            </div>


            <div className="flex justify-center">
              <button className="text-white text-[14x] border-solid rounded-3xl bg-clock px-4 py-2 uppercase" onClick={() => HardReload()}>Hard Reload</button>
            </div>

            <div className="flex justify-center mt-4">
              <button className="text-white text-[14x] border-solid rounded-3xl bg-clock px-4 py-2 uppercase" onClick={() => toggleFullscreen()}>{isFullscreen ? "Exit FullScreen" :"Enter FullScreen"}</button>
            </div>
          </div>
        )}

        {/* Screen Saver render */}
        {screensaver && (
          <div
            id="screenSaver"
            className="h-full w-full absolute top-0 z-50"
            onClick={() => {
              setScreenSaver(false);
              $("#container").delay(500).fadeIn();
              $(".bg-button-active").click();
              gsap_anims.intro();
              gsap_anims.show("#agenda .gsap-stag-show");
              gsap_anims.show_moving_up(
                "#agenda .gsap-stag-moving-up"
              );
              restartTabs();
            }}
            onTouchStart={() => { setScreenSaver(false);
              $("#container").delay(500).fadeIn();
              $(".bg-button-active").click();
              gsap_anims.intro();
              gsap_anims.show("#agenda .gsap-stag-show");
              gsap_anims.show_moving_up(
                "#agenda .gsap-stag-moving-up"
              );
              restartTabs();
            }}
              
         >
          <div className="touch-badge"><img src="/assets/badge_touch.png"/></div>
            <video
              autoPlay
              playsInline
              muted
              className="absolute top-0 left-0 w-full h-full object-cover"
              onEnded={() => {
                setScreenSaver(false);
                $(".bg-button-active").click();
                $("#container").delay(1000).fadeIn();
                gsap_anims.intro();
                gsap_anims.show("#agenda .gsap-stag-show");
                gsap_anims.show_moving_up(
                  "#agenda .gsap-stag-moving-up"
                );
                restartTabs();
                resetTimeout();
              }}
            >
              <source src={currentVideo as string}  type="video/webm" />
            </video>
          </div>
        )}

        {/* MODAL TIMER */}
        {modal && (
          <div
            id="inactivity-modal"
            className="w-full h-full fixed inset-0 flex flex-col items-center justify-center bg-opacity-50 z-[100] bg-timer-bg"
          >
            <div className="bg-timer-modal shadow-lg p-8 max-h-xl h-2/5 w-4/6 flex flex-col justify-center items-center rounded-4xl border-modal-gradient">
              <div className="border-gradient">
                <button className="h-80 w-80 rounded-full text-[120px] bg-title p-4 border-solid font-bold">
                  {timerModal}
                </button>
              </div>
              <div className="mt-10">
                <p className="text-high rounded-full bg-title p-4 border-solid font-bold">Are you still there?</p>
              </div>
              <div className="flex justify-center mt-16 w-auto">
                <button
                  id="stay-active-btn"
                  className="text-white text-high button-idle w-auto h-auto p-8 rounded-full border-2 border-button-gradient px-28 py-6 bg-clock font-normal"
                  onClick={() => handleStayActive()}
                >
                  Yes, continue viewing
                </button>
              </div>
            </div>
          </div>
        )}

        {/* WIFI MODAL */}
        {wifi && (
          <div
            id="wifi-modal"
            className="w-full h-full fixed inset-0 flex flex-col items-center justify-center bg-opacity-50 z-50 bg-timer-bg"
          >
            {/* Header Modal */}
            <div className="w-5/6 flex items-center mb-4">
              <div className="flex justify-start items-center w-1/2 wifi-modal-stag opacity-0">
                <svg
                  width="52"
                  height="40"
                  viewBox="0 0 26 19"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.992 19c-.676 0-1.268-.254-1.775-.762-.508-.508-.761-1.101-.761-1.779 0-.677.253-1.27.76-1.778.508-.509 1.1-.763 1.776-.763.676 0 1.268.254 1.775.763.507.508.76 1.1.76 1.778 0 .678-.253 1.27-.76 1.779-.507.508-1.099.762-1.775.762zm0-12.224c1.465 0 2.836.258 4.114.776 1.277.518 2.508 1.285 3.691 2.3.263.227.404.514.423.862.019.348-.104.654-.367.918-.263.263-.563.385-.901.367a1.602 1.602 0 0 1-.93-.367c-.996-.829-1.996-1.421-3.001-1.78a8.982 8.982 0 0 0-3.03-.536 8.982 8.982 0 0 0-3.028.537c-1.005.358-2.006.95-3.001 1.779a1.602 1.602 0 0 1-.93.367c-.338.018-.639-.104-.902-.367-.263-.264-.385-.57-.366-.918.019-.348.16-.635.423-.861C6.37 8.837 7.6 8.07 8.877 7.552c1.278-.518 2.65-.776 4.115-.776zm0-6.776c2.404 0 4.673.456 6.805 1.37a21.034 21.034 0 0 1 5.762 3.655c.263.245.409.541.437.89.028.348-.09.654-.352.917-.263.264-.578.39-.944.381a1.501 1.501 0 0 1-.972-.38 20.127 20.127 0 0 0-5.016-3.135c-1.785-.771-3.691-1.157-5.72-1.157s-3.936.386-5.72 1.157a20.127 20.127 0 0 0-5.016 3.134 1.484 1.484 0 0 1-.958.381 1.222 1.222 0 0 1-.93-.38c-.263-.264-.385-.57-.366-.918a1.27 1.27 0 0 1 .423-.89A21.034 21.034 0 0 1 6.187 1.37C8.319.456 10.587 0 12.992 0z"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                </svg>
                <p className="text-high text-white bg-title font-bold ml-8">
                  Wi-Fi Information
                </p>
              </div>

              <div
                className="flex justify-end items-center w-1/2 wifi-modal-stag opacity-0"
                onClick={() => handleWifi()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="white"
                  className="size-14 relative justify-end"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>

            <div className="w-full h-5 relative mb-8 wifi-modal-stag opacity-0">
              <img
                src="/assets/header/divider.svg"
                className="divider flex justify-center items-center m-auto w-5/6"
              />
            </div>

            {/* Content Modal */}
            <div className="bg-wifi-modal rounded-3xl shadow-lg p-8 max-h-xl h-4/6 w-5/6 flex flex-col justify-center items-center border-solid border-2 border-wifi-gradient wifi-modal-stag opacity-0">
              <div className="h-full w-4/5 flex flex-col justify-center px-50">
                <img id="qr" src="/assets/OncForum_Wifi_13oct.svg" className="h-1/4 wifi-modal-stag opacity-0" />

                <p className="text-white text-medium w-auto text-center mt-10 leading-tight wifi-modal-stag opacity-0">
                  Scan the QR Code to connect
                  <br />
                  to the Wi-Fi network
                </p>

                <div className="flex justify-around justify-items-center items-center m-auto mt-20 mb-0 w-5/6 wifi-modal-stag opacity-0">
                  <svg width="100" height="2">
                    <line
                      x1="0"
                      y1="1"
                      x2="100"
                      y2="1"
                      stroke="rgba(255, 255, 255, 1)"
                      strokeWidth="1"
                      strokeOpacity="0.4"
                    />
                  </svg>
                  <p className="text-medium bg-title text-center">
                    or access manually
                  </p>
                  <svg width="100" height="2">
                    <line
                      x1="0"
                      y1="1"
                      x2="100"
                      y2="1"
                      stroke="rgba(255, 255, 255, 1)"
                      strokeWidth="1"
                      strokeOpacity="0.4"
                    />
                  </svg>
                </div>

                <div className="m-auto mt-20 mb-0 w-[30rem] flex flex-col">
                  <p className="text-white text-small px-4 wifi-modal-stag opacity-0">Network</p>
                  <div className="w-full h-20 bg-wifi-inputs rounded-xl mb-10 px-4 wifi-modal-stag opacity-0">
                    <p className="text-white text-medium h-full w-full flex justify-start items-center">
                      OncForum
                    </p>
                  </div>

                  <p className="text-white text-small px-4 wifi-modal-stag opacity-0">Password</p>
                  <div className="w-full h-20 bg-wifi-inputs rounded-xl mb-10 px-4 wifi-modal-stag opacity-0">
                    <p className="text-white text-medium h-full w-full flex justify-start items-center">
                      Rome2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* MAP MODAL */}
        {map && (
          <div
            id="map-modal"
            className="w-full h-full fixed inset-0 flex flex-col items-center justify-center bg-opacity-50 z-50 bg-timer-bg"
          >
            {/* Header Modal */}
            <div className="w-5/6 flex items-center mb-4">
              <div className="flex justify-start items-center w-1/2 map-modal-stag opacity-0">
                <svg
                  width="52"
                  height="40"
                  viewBox="0 0 26 19"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.992 19c-.676 0-1.268-.254-1.775-.762-.508-.508-.761-1.101-.761-1.779 0-.677.253-1.27.76-1.778.508-.509 1.1-.763 1.776-.763.676 0 1.268.254 1.775.763.507.508.76 1.1.76 1.778 0 .678-.253 1.27-.76 1.779-.507.508-1.099.762-1.775.762zm0-12.224c1.465 0 2.836.258 4.114.776 1.277.518 2.508 1.285 3.691 2.3.263.227.404.514.423.862.019.348-.104.654-.367.918-.263.263-.563.385-.901.367a1.602 1.602 0 0 1-.93-.367c-.996-.829-1.996-1.421-3.001-1.78a8.982 8.982 0 0 0-3.03-.536 8.982 8.982 0 0 0-3.028.537c-1.005.358-2.006.95-3.001 1.779a1.602 1.602 0 0 1-.93.367c-.338.018-.639-.104-.902-.367-.263-.264-.385-.57-.366-.918.019-.348.16-.635.423-.861C6.37 8.837 7.6 8.07 8.877 7.552c1.278-.518 2.65-.776 4.115-.776zm0-6.776c2.404 0 4.673.456 6.805 1.37a21.034 21.034 0 0 1 5.762 3.655c.263.245.409.541.437.89.028.348-.09.654-.352.917-.263.264-.578.39-.944.381a1.501 1.501 0 0 1-.972-.38 20.127 20.127 0 0 0-5.016-3.135c-1.785-.771-3.691-1.157-5.72-1.157s-3.936.386-5.72 1.157a20.127 20.127 0 0 0-5.016 3.134 1.484 1.484 0 0 1-.958.381 1.222 1.222 0 0 1-.93-.38c-.263-.264-.385-.57-.366-.918a1.27 1.27 0 0 1 .423-.89A21.034 21.034 0 0 1 6.187 1.37C8.319.456 10.587 0 12.992 0z"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                </svg>
                <p className="text-high text-white bg-title font-bold ml-8">
                  Floor Plan
                </p>
              </div>

              <div
                className="flex justify-end items-center w-1/2 map-modal-stag opacity-0"
                onClick={() => setMap(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="white"
                  className="size-14 relative justify-end"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>

            <div className="w-full h-5 relative mb-8 map-modal-stag opacity-0">
              <img
                src="/assets/header/divider.svg"
                className="divider flex justify-center items-center m-auto w-5/6"
              />
            </div>

            {/* Content Modal */}
            <div className="w-5/6 relative mb-8 map-modal-stag opacity-0">
              <p className="text-white text-medium leading-[1.2]">
                Explore the Venue: Where your journey to Envision
                Limitless—Dream it. Drive it. Begins.
              </p>
            </div>
            
            <div className="bg-map-modal rounded-3xl shadow-lg max-h-xl h-fit mb-20 w-5/6 flex flex-col justify-center items-center border-solid border-2 border-idle map-modal-stag opacity-0">
            <QuickPinchZoom onUpdate={onUpdate}>
              <div className="h-full flex flex-col justify-start" ref={imgRef}>
              
                <div className="flex h-full rounded-3xl relative">
                
                  { positionMap.pos !== '' && 
                    <div id="pinMap" className={`absolute w-full h-full z-20`} >

                      <span className="z-10 absolute flex flex-col justify-center items-center w-fit h-10" style={{ left: `${positionMap.x}px`, top: `${positionMap.y}px` }}>
                        <img
                          id="map"
                          src="/assets/you-are-here.svg"
                          className="rounded-3xl w-[46px]"
                        />
                      </span>

                    </div>
                  }
                  
                    <img
                      id="map"
                      src="/assets/map.webp"
                      className="rounded-3xl"
                    />
                
                </div>
              </div>
            </QuickPinchZoom>
            </div>
          </div>
        )}

        <div id="container" className="h-full w-full">
          <div
            id="header"
            className="h-[10%] w-full flex justify-start items-center pb-4 top-0 mb-10 relative"
          >
            <div className="text-6xl text-white flex-grow text-center flex justify-start w-3/6 ml-12 logo-container">
              <img
                src="/assets/header/logo.png"
                className="w-1/3 h-full pointer-events-none"
              />
            </div>

            <div className="w-[55%] h-[2px] bg-white/40 absolute left-[50%] translate-x-[-45%] slash"></div>

            <button
              onClick={() => handleWifi()}
              className="wifi-info w-auto h-[50px] absolute left-[50%] translate-x-[-45%] bottom-0 rounded-full px-6 bg-wifi flex justify-between items-center border-solid border-2 wifi-btn border-wifi-info-gradient "
            >
              <svg
                width="26"
                height="19"
                viewBox="0 0 26 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.992 19c-.676 0-1.268-.254-1.775-.762-.508-.508-.761-1.101-.761-1.779 0-.677.253-1.27.76-1.778.508-.509 1.1-.763 1.776-.763.676 0 1.268.254 1.775.763.507.508.76 1.1.76 1.778 0 .678-.253 1.27-.76 1.779-.507.508-1.099.762-1.775.762zm0-12.224c1.465 0 2.836.258 4.114.776 1.277.518 2.508 1.285 3.691 2.3.263.227.404.514.423.862.019.348-.104.654-.367.918-.263.263-.563.385-.901.367a1.602 1.602 0 0 1-.93-.367c-.996-.829-1.996-1.421-3.001-1.78a8.982 8.982 0 0 0-3.03-.536 8.982 8.982 0 0 0-3.028.537c-1.005.358-2.006.95-3.001 1.779a1.602 1.602 0 0 1-.93.367c-.338.018-.639-.104-.902-.367-.263-.264-.385-.57-.366-.918.019-.348.16-.635.423-.861C6.37 8.837 7.6 8.07 8.877 7.552c1.278-.518 2.65-.776 4.115-.776zm0-6.776c2.404 0 4.673.456 6.805 1.37a21.034 21.034 0 0 1 5.762 3.655c.263.245.409.541.437.89.028.348-.09.654-.352.917-.263.264-.578.39-.944.381a1.501 1.501 0 0 1-.972-.38 20.127 20.127 0 0 0-5.016-3.135c-1.785-.771-3.691-1.157-5.72-1.157s-3.936.386-5.72 1.157a20.127 20.127 0 0 0-5.016 3.134 1.484 1.484 0 0 1-.958.381 1.222 1.222 0 0 1-.93-.38c-.263-.264-.385-.57-.366-.918a1.27 1.27 0 0 1 .423-.89A21.034 21.034 0 0 1 6.187 1.37C8.319.456 10.587 0 12.992 0z"
                  fill="#FFF"
                  fillRule="nonzero"
                />
              </svg>
              <p className="text-small text-white ml-4">Wi-Fi Information</p>
            </button>

            <div className="flex w-3/6 justify-end mr-12 time">
              <div className="w-auto p-3 rounded-full flex bg-clock">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="white"
                  className="size-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

                <p className="text-small text-white ml-2">
                  {time.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </p>
              </div>
            </div>
          </div>

          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route
              path="/"
              element={
                <Agenda
                  agenda={agenda}
                  events={events}
                  screensaver={screensaver}
                  setMap={setMap}
                  map={map}
                />
              }
            />
            <Route
              path="activities"
              element={
                <Activities
                  activities={activities}
                  eventsActivities={eventsActivities}
                  time={time}
                  screensaver={screensaver}
                  setMap={setMap}
                  map={map}
                />
              }
            />
          </Routes>

          <div
            id="footer"
            className="h-[8%] w-full bottom-0 flex items-center justify-center absolute"
          >
            <div
              id="notch"
              className="relative w-2/4 bg-qr-scan h-full rounded-t-4xl flex content-center px-4 items-center justify-center"
            >
              <img
                id="qr"
                src="/assets/qr_app.png"
                className="h-2/3 bg-white"
              ></img>
              <p className="text-medium text-white ml-6">
                Scan the QR Code to
                <br />
                access the meeting app
              </p>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
