import React from "react";
import { DayAgenda, AgendaData } from "../../types";

interface findCurrentAndNextEventsProps {
  agenda: { agenda: DayAgenda[] } | undefined;
  time: Date;
}


const findNextDayEvents = (
  agenda: findCurrentAndNextEventsProps["agenda"],
  today: string
) => {
  if (!agenda) return null;

  const todayIndex = agenda.agenda.findIndex(
    (day) => day.date.split("T")[0] === today
  );

  for (let i = todayIndex + 1; i < agenda.agenda.length; i++) {
    const dayAgenda = agenda.agenda[i];
    if (dayAgenda.events.length > 0) {
      return { events: dayAgenda.events, date: dayAgenda.date }; // Retorna todos los eventos del próximo día con eventos
    }
  }

  return null; // No hay más eventos en los próximos días
};



const findCurrentAndNextEvents = (
  agenda: findCurrentAndNextEventsProps["agenda"],
  time: findCurrentAndNextEventsProps["time"]
) => {
  const today = new Date().toISOString().split("T")[0];
  const currentTime = time.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const todayAgenda = agenda?.agenda.find(
    (day) => day.date.split("T")[0] === today
  );

  let currentEvents = [];
  let nextEvents = [];

  if (!todayAgenda) {
    return { currentEvents: null, nextEvents: null };
  }

  // Encontrar todos los eventos actuales
  for (let i = 0; i < todayAgenda.events.length; i++) {
    const event = todayAgenda.events[i];
    const eventStart = event.starts;
    const eventEnd = event.ends;

    if (currentTime >= eventStart && currentTime < eventEnd) {
      currentEvents.push(event);
    }
  }

  // Si no hay eventos actuales, encontrar los próximos eventos
  if (currentEvents.length === 0) {
    for (let i = 0; i < todayAgenda.events.length; i++) {
      const event = todayAgenda.events[i];
      const eventStart = event.starts;

      if (currentTime < eventStart) {
        nextEvents.push(event);
      }
    }
  }

  const nextDayEvents = nextEvents.length === 0 ? findNextDayEvents(agenda, today) : null;

  let upcomingEvents = nextEvents.length > 0 
    ? { events: nextEvents, date: todayAgenda.date }
    : nextDayEvents;

  return { currentEvents, upcomingEvents, todayAgenda: { todayAgenda } };
};

export default findCurrentAndNextEvents;
